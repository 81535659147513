a.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.btn-center {
  justify-content: center;
}
.btn-left {
  justify-content: start;
}
.btn {
  display: inline-flex;
  align-items: center;
  border: none;
  color: white;
  font-size: 2rem;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
}

.btn-sm {
  padding: 0.5rem 1.4rem;
}

.btn-lg,
.btn-xl {
  padding: 0.8rem 1.5rem;
}

.btn-label {
  margin-left: 1.1625rem;
  font-weight: normal;
  line-height: 2.325rem;
  letter-spacing: 0;
  font-size: 2rem;
}

.btn-lg .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-height: 2.3rem;
  max-width: 2.3rem;
}
.btn-xl .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.7rem;
  height: 2.7rem;
}

.btn-primary {
  background: transparent;
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
}

.btn-primary:hover {
  background: var(--dark-blue-hover);
}
.btn-primary:active {
  background: var(--dark-blue-active);
}
